import { CARDS_EN, CONTACT_PAGE_EN, CORE_EN, FORMS_EN, GLOBAL_LOCATIONS_EN, KNOWLEDGE_CENTER_EN, NEWS_EN, SHARED_EN } from './en';

export const EN = {
  GENERAL: {
    CARDS: CARDS_EN,
    FORMS: FORMS_EN,
    NAVIGATION: {
      GET_IN_TOUCH: 'Get in touch',
      READ_MORE: 'Read more',
      MOBILE: {
        LINK_TEXT: 'Learn More',
      },
    },
    NEWSLETTER_LINK_TEXT: 'Subscribe to our newsletter',
    SEARCH: {
      SEARCH_SPS: 'Search SPS',
      SEARCH_BUTTON: 'Search',
      POPULAR: 'Popular searches:',
      TERMS: {
        DIGITAL_TRANSFORMATION: 'Digital Transformation',
        BANKING_SOLUTIONS: 'Banking Solutions',
        'SPS CAREERS': 'SPS Careers',
      },
    },
    MINUTES: '{{minutes}} minutes',
  },
  MODULES: {
    CORE: CORE_EN,
    KNOWLEDGE_CENTER: KNOWLEDGE_CENTER_EN,
    NEWS: NEWS_EN,
    GLOBAL_LOCATIONS: GLOBAL_LOCATIONS_EN,
    CONTACT_PAGE: CONTACT_PAGE_EN,
  },
  SHARED: SHARED_EN,
};
