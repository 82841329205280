export const GLOBAL_LOCATIONS_DE = {
  INTRODUCTION: {
    HEADLINE: 'Our locations',
    TEXT: 'SPS strebt als führendes technologieorientiertes Unternehmen eine glänzende Zukunft an, die auf einer starken und stolzen Vergangenheit aufbaut. Wir können auf mehr als 20 Jahre Erfahrung zurückblicken, in denen wir das Leben unserer Kunden in mehr als 20 Ländern durch einen kundenorientierten Ansatz, der Exzellenz und Innovation als Grundwerte beinhaltet, erleichtert haben.<br/><br/>Mit einem erstklassigen Net Promoter Score von 80 und einer Kundenbindungsrate von über 95 % vertrauen Hunderte von Kunden aus verschiedenen Branchen, darunter Banken, Finanzdienstleister, Versicherungen und Unternehmen aus dem Gesundheitswesen, auf unsere Lösungen und unser multidisziplinäres Team aus mehr als 8.000 Spezialisten. Steigern Sie die Effizienz und Produktivität Ihres Unternehmens mit uns.',
  },
  LINK: {
    EMAIL: 'Kontaktieren Sie uns uns',
    PARTNER_WEBSITE: 'Besuchen Sie unsere Partner-Webseite',
  },
  REGIONS: {
    ALL: 'Alle',
    APAC: 'APAC',
    EMEA: 'EMEA',
    NA: 'USA',
  },
  TYPE: {
    GLOBAL_HEADQUARTER: 'Global Headquarter',
    HEADQUARTERS: 'Headquarters',
  },
  COUNTRIES: {
    CH: 'Schweiz',
    DE: 'Deutschland',
    US: 'Vereinigte Staaten',
    UK: 'Vereinigtes Königreich',
    IT: 'Italien',
    NL: 'Niederlande',
    AT: 'Österreich',
    FR: 'Frankreich',
    SG: 'Singapur',
    CN: 'China',
    HK: 'Hong Kong',
    VN: 'Vietnam',
    IR: 'Irland',
    ES: 'Spanien',
    IN: 'Indien',
    PH: 'Philippinen',
    TW: 'Taiwan',
    JP: 'Japan',
    AUS: 'Australien',
    NZ: 'Neuseeland',
    SC: 'Südkorea',
    PL: 'Polen',
    HU: 'Ungarn',
  },
};
