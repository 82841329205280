import { Component, computed, inject, OnInit, Signal } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs';
import { PageMetasSelectors, PageMetasActions } from '@store/page-metas';

import { PrivacyService } from '@services/privacy/privacy.service';

import { LinkComponent } from '@components/common/link/link.component';
import { ReactiveComponent } from '@core/components/base/reactive-component';
import { SocialLinksComponent } from '@components/common/social-links/social-links.component';
import { UrlFixedPipe, SanitizePipe } from '../../../pipes';
import { HtmlHelper } from '@lib/utils/helpers';

import { Footer } from '@interfaces/index';

import buildVersion from '../../../../public/version.json';

@Component({
  standalone: true,
  selector: 'sps-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [SanitizePipe, LinkComponent, SocialLinksComponent, DatePipe],
})
export class FooterComponent extends ReactiveComponent implements OnInit {
  public $navConfig: Signal<Footer.Config>;
  public versionInfo: { version: string; date: Date };
  public selectedLanguage$: Observable<string>;
  public currentYear = new Date().getFullYear();

  private privacyService = inject(PrivacyService);

  ngOnInit(): void {
    this.getItems();
    this.fetchItemsIfNeeded();

    this.selectedLanguage$ = this.store.select(PageMetasSelectors.selectCurrentLanguage(true));
    this.versionInfo = { version: buildVersion.version, date: new Date(buildVersion.timestamp) };
  }

  public onClick(action: string = null): void {
    switch (action) {
      case 'showCookieDialog':
        this.showCookieDialog();
        break;
    }
  }

  public showCookieDialog(): void {
    this.privacyService.showCookiebotDialog();
  }

  private getItems() {
    const cookieLink: Footer.FooterLink = {
      href: null,
      label: 'Cookie Setup',
      onClick: 'showCookieDialog',
    };

    const $navConfig = this.store.selectSignal(PageMetasSelectors.selectCurrentFooterConfig);

    this.$navConfig = computed(() => {
      const config = $navConfig();

      if (!config?.links?.length) {
        return null;
      }

      const fixedConfig = this.fixPdfUrls(config);

      return {
        ...fixedConfig,
        // Inject cookie link as 5th item
        links: config.links.reduce((links, obj, i) => (i === 4 ? [...links, cookieLink, obj] : [...links, obj]), []),
      };
    });
  }

  private fetchItemsIfNeeded(): void {
    if (this.$navConfig()?.links?.length) {
      return;
    }

    this.store.dispatch(PageMetasActions.fetchFooterNavItems());
  }

  // Temporarily fix for SPSCR-328, needs to be fixed in the CMS
  private fixPdfUrls(config: Footer.Config): Footer.Config {
    const fixed = config.sections.map(section => UrlFixedPipe.transform(section));

    if (this.isServer) {
      return { ...config, sections: fixed };
    }

    const fixedWithTarget = fixed.map(HtmlHelper.addPdfTargets);

    return { ...config, sections: fixedWithTarget };
  }
}
