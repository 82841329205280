export const KNOWLEDGE_CENTER_EN = {
  COMPONENTS: {
    INDEX: {
      SUB_HEADLINE:
        'Access a wide range of resources that will help you to empower your organization and unlock business process efficiencies.',
      INTRODUCTION: 'Discover new insights to boost growth and productivity in your company.',
      NO_RESULTS: 'Your filtered search gave no results.',
      FILTERS: {
        DOCUMENT_TYPES: {
          ALL: 'All',
          VIDEO: 'Videos',
          WHITEPAPER: 'Whitepapers',
          EVENT: 'Events',
          ARTICLE: 'Articles',
          BROCHURE: 'Brochures',
          INFOGRAPHIC: 'Infographic',
          MARKETRESEARCH: 'Market Research',
          CASESTUDY: 'Case Studies',
          FACTSHEET: 'Fact sheets',
          PODCAST: 'Podcasts',
          VIDEOS: 'Videos',
        },

        // KC PHASE 2
        // BLOG_CATEGORIES: {
        //   ALL: 'All',
        //   AITEC: 'AI & Tec',
        //   ABOUTSPS: 'About SPS',
        //   BUSINESSEFFICIENCY: 'Business efficiency',
        //   CUSTOMEREXPERIENCE: 'Customer experience',
        //   EMPLOYEEEXPERIENCE: 'Employee experience',
        //   ESG: 'ESG',
        //   SMARTWORKPLACES: 'Smart workplaces',
        // },
        // KC PHASE 1
        SOLUTION_CATEGORIES: {
          ALL: 'All',
          DOCUMENT_MANAGEMENT_SERVICES: 'Document management services',
          BUSINESS_PROCESS_SERVICES: 'Business process services',
          INTELLIGENT_AUTOMATION: 'Intelligent automation',
          DIGITAL_TRANSFORMATION: 'Digital transformation',
        },
        INDUSTRY_TYPES: {
          ALL: 'All',
          BANKING: 'Banking',
          INSURANCE: 'Insurance',
          LEGAL: 'Legal',
          HEALTHCARE: 'Healthcare',
        },
      },
      FORM: {
        SEARCH: {
          PLACEHOLDER: 'Search Knowledge Center',
        },
        SELECT: {
          LABELS: {
            DOCUMENT_TYPE: 'Document type',
            // TOPIC: 'Topic',
            SOLUTION: 'Solution',
            INDUSTRY: 'Industry',
          },
        },
      },
    },
  },
};
