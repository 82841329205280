import { Routes } from '@angular/router';
import { CMS_PAGES_ROUTES } from './modules/cms-pages/cms-pages.routes';
import { NEWS_CENTER_ROUTES } from './modules/news-center/news-center.routes';
import { PAGES_ROUTES } from './modules/pages/pages.routes';
import { STATIC_PAGES_ROUTES } from './modules/static-pages/static-pages.routes';

import { LanguagesEnum } from '@core/config';

const languageRoutes: Routes = Object.values(LanguagesEnum).map(lang => ({
  path: lang.toLowerCase(),
  children: [
    {
      path: 'news',
      data: { canonical: 'news' },
      children: NEWS_CENTER_ROUTES,
    },
    {
      path: 'pages',
      children: PAGES_ROUTES,
    },
    {
      path: '**',
      children: CMS_PAGES_ROUTES,
    },
    { path: '**', redirectTo: '/site/404' },
  ],
}));

export const APP_ROUTES: Routes = [
  {
    path: 'site',
    children: STATIC_PAGES_ROUTES,
  },
  ...languageRoutes,
  { path: '**', redirectTo: '/site/404' },
];
