<sps-header/>

<main>
  <router-outlet/>
  @defer (on idle) {
    <sps-side-widget/>
  } @placeholder {
    <div></div>
  } @error {
    <div></div>
  }
</main>

@defer (on idle) {
  <sps-toast/>
  <sps-dialog/>
  <sps-loading-indicator/>
  <sps-social-widget platform="LINKEDIN"/>
  <sps-scroll-top/>
} @placeholder {
  <div></div>
} @error {
  <div></div>
}

@defer (on viewport) {
  <sps-newsletter-subscription-cta/>
} @placeholder {
  <div></div>
} @error {
  <div></div>
}

<sps-footer/>
<sps-json-ld/>
