import { Inject, Injectable, makeStateKey, Optional, StateKey, TransferState } from '@angular/core';
import { Location } from '@angular/common';

import { debounceTime } from 'rxjs';

import { Store } from '@ngrx/store';
import { RootActions } from '@store/root.actions';
import { selectRootState } from '@store/root.selectors';
import { initialState, RootState } from '@store/index';

import { CmsRestApiService } from '@services/api/cms-rest-api/cms-rest-api.service';
import { LanguageService } from '@services/language/language.service';

import { IS_SERVER, LanguagesEnum, SELECTED_LANGUAGE, TLanguage } from '@core/config';
import { SpsLogger } from '@lib/utils/logger';

@Injectable({ providedIn: 'root' })
export class TransferStateManager {
  private readonly transferStateKey = makeStateKey<RootState>('rootState');

  constructor(
    @Inject(IS_SERVER) private isServer: boolean,
    @Inject(SELECTED_LANGUAGE) @Optional() private selectedLanguage: TLanguage,
    @Inject(Location) private location: Location,
    private transferState: TransferState,
    private languageService: LanguageService,
    private store: Store<RootState>
  ) {}

  public async init(): Promise<TLanguage> {
    const selectedLanguageKey = makeStateKey<TLanguage>('SELECTED_LANGUAGE');

    if (!this.isServer) {
      this.rehydrateState();
    }

    // Static pages are (except for search results) english only - so it's okay to quit here
    const path = this.location.path();
    if (path.startsWith('/site') && !path.includes('/site/search')) {
      return LanguagesEnum.EN;
    }

    this.language = this.selectedLanguage;
    this.language ||= this.getLanguageFromTransferState(selectedLanguageKey);
    this.language ||= this.getLanguageFromLocation();

    if (!this.language) {
      const preferredLanguage = this.languageService.getLanguage();

      if (preferredLanguage) {
        return Promise.reject(preferredLanguage.toLowerCase());
      }
    }

    this.language ||= LanguagesEnum.EN;

    if (this.isServer) {
      this.transferState.set<string>(selectedLanguageKey, this.language);
      this.serializeState();
    }

    return this.language;
  }

  set language(language: TLanguage) {
    if (!language) {
      return;
    }

    this.transferState.set<TLanguage>(makeStateKey('selectedLanguage'), language);
  }

  get language(): TLanguage | null {
    return this.transferState.get(makeStateKey<TLanguage>('selectedLanguage'), null);
  }

  private getLanguageFromTransferState(key: StateKey<TLanguage>): TLanguage | null {
    if (this.transferState.hasKey(key)) {
      return this.transferState.get(key, null);
    }

    return null;
  }

  private getLanguageFromLocation(): TLanguage | null {
    if (this.location.path()?.length) {
      return CmsRestApiService.extractLanguage(this.location.path());
    }

    return null;
  }

  private serializeState(): void {
    this.store
      .select(selectRootState)
      .pipe(debounceTime(50))
      .subscribe(state => {
        this.transferState.set<RootState>(this.transferStateKey, state);
      });
  }

  private rehydrateState(): void {
    if (!this.transferState.hasKey(this.transferStateKey)) {
      return;
    }

    try {
      const state = this.transferState.get<RootState>(this.transferStateKey, initialState);
      this.store.dispatch(RootActions.rehydrate({ state }));
    } catch (e) {
      SpsLogger.warn(e);
    }
  }
}
