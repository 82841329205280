@if ($active()) {
  <nav>
    <ul class="container level-1">

      @if(config?.selectedItem) {
        <li class="headline">
          <div role="button" tabindex="0" (click)="back()" (keyup.enter)="back()">
            <sps-icon icon="navigate_before"/>
            {{ config.selectedItem }}
          </div>
        </li>
      }

      @for (item of config.items; track item) {
        <li>

          @if (depths === 0) {
            @if (item.children.length === 0) {
              <a [routerLink]="item.href">{{ item.label }}</a>
            } @else {
              <div role="button" tabindex="0" [routerLink]="item.href">
                {{ item.label }}
                <sps-icon icon="navigate_next" (click)="selectItem(item)" (keyup.enter)="selectItem(item)"/>
              </div>
            }
          } @else if (depths > 0) {
            <ul class="level-2">
              <li class="headline">
                {{ item.label }}
                <sps-link [href]="item.href">{{ "GENERAL.NAVIGATION.MOBILE.LINK_TEXT" | translate }}</sps-link>
              </li>

              @for (child of item.children; track child) {
                <li><a [routerLink]="child.href">{{ child.label }}</a></li>
              }
            </ul>
          }
        </li>
      }

      <li class="language-selection">
        @for (lang of languages; track $index) {
          <a  sps-button secondary routerLink="/{{lang.value.toLowerCase()}}"  [class.selected]="currentLanguage === lang">
            {{ ('MODULES.CORE.TOP_NAV.LANGUAGES.' + lang.i18n) | translate }}
          </a>
        }
      </li>

      <li>
        <a [routerLink]="contactUsPath" class="contact">
          <span sps-button secondary>
            {{ "GENERAL.NAVIGATION.GET_IN_TOUCH" | translate }}
          </span>
        </a>
      </li>

    </ul>
  </nav>

  <div class="backdrop" (click)="active= false"></div>
}
